.card {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 2%;
    margin-bottom: 2%;
    justify-content: center;
  }