*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  
}
body{
  background: #f5f5f5;
}


@media only screen and (max-width: 768px) {
  /* Styles for screens less than or equal to 768px wide */
  .container {
      width: 100%;  /* Make container full width on small screens */
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  /* Styles for screens between 768px and 992px wide */
  .content {
      font-size: 18px;  /* Adjust font size for medium screens */
  }
}