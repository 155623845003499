.privacyhead {
  text-align: left;
  color: #000;
  text-align: left;
  font-size: 20px;
}
.privaytext {
  text-align: justify;
  font-family: sans-serif;
  font-size: 16px;
  padding-left: 18px;
  color: #858687;
}
@media only screen and (max-width: 600px) {
  .privacyhead {
    font-size: 18px; /* Font size for mobile devices */
  }

  .privacytext {
    font-size: 14px; /* Font size for mobile devices */
  }
}