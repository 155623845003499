/* .Box {
  padding: 5% 2.5%;
  background: #ff5733;
  width: 100%;
  bottom: 0;
}

.FooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;

  margin: 0 auto;
}

.Row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  grid-gap: 20px;
}

.Column {
  display: flex;
  flex-direction: column;
  text-align: left;
  /* margin-left: 60px; */
/* } */

/* @media (max-width: 1000px) {
  .Row {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.FooterLink {
  color: #fff;
  margin-bottom: 20px;
  font-size: 16px;
  text-decoration: none;
} */

/* .FooterLink:hover {
  color: #000;
  transition: 200ms ease-in;
}

.Heading {
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
  font-weight: bold;
} */

/* .CopyrightText {
  text-align: center;
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
}

.socialicon {
  margin-right: 6px;
}
.contacticon {
  margin-right: 6px;
}  */

.section_padding{
  padding: 2rem 2rem;
}
.footer {
  background-color: #ff5733;
  color: #fff;
  padding: 40px 70px;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  color: #000;
}


.sb_footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sb_footer-links-div {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.sb_footer-links-div h4 {
  margin-bottom: 30px;
  font-size: 22px;
}

.sb_footer-links-div p,
.sb_footer-links-div a {
  margin: 14px 0;
}

.socialicon,
.contacticon {
  margin-right: 10px;
}

.FooterLink {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.CopyrightText {
  text-align: center;
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
}
.CopyrightText p{
  text-align: center;
  
}
.socialicon {
  margin-right: 6px;
}

/* Responsive Styles */
/* @media (max-width: 768px) {
  .sb_footer-links {
    flex-direction: column;
    align-items: center;
  }
  .sb_footer-links-div {
    margin: 20px 0;
  }
}

@media (max-width: 480px) {
  .sb_footer-links-div h4 {
    text-align: center;
  }
  .sb_footer-links-div p,
  .sb_footer-links-div a {
    text-align: center;
  }
  .sb_footer-below {
    flex-direction: column;
  }
} */

/* .footer{
  background-color: #ff5733;
}
.sb_footer{
  display: flex;
flex-direction: column;
}
.sb_footer-links{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}
.sb_footer-links-div{
  width: 150px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: #fff;
}
.a{
  color: rgb(175, 175, 179);
  text-decoration: none;
}
.socialmedia{
  display: flex;
  flex-direction: row;
}
.sb_footer-links-div h4{
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0.9rem;
}
.sb_footer-links-div p{
  font-size: 12px;
  line-height: 15px;
  margin: 0.5rem 0;
  cursor: pointer;
}
.sb_footer-below{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
}
hr{
  color: #fff;
  width: 100%;
}
.sb_footer-copyright p{
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  font-weight: 600;
} */

/* playstore-button */

.app-download-container {
  display: flex;
  text-align: center;
  padding-left: 22%;
  font-family: 'Arial', sans-serif;
}

.download-text {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
}

.store-button {
  height: 55px; /* Adjust as needed */
  transition: transform 0.2s;
  margin-top: -15px;
  padding-left: 10px;
}

.store-button:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}