.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.image-section {
  text-align: center;
  margin-bottom: 20px;
}

.contact-image {
  max-width: 65%;
  height: auto;
}

.info-section {
  max-width: 600px;
  margin-top: 20px;
  text-align: left;
  background-color: #ff5733;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.102);
  border-radius: 0.5rem;
  padding: 1.5rem;
  transition: transform 0.3s ease-in-out;
}

.info-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.info-item {
  margin-bottom: 15px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1.5rem;
  transition: transform 0.3s ease-in-out;
}
.info-item:hover {
  background-color: #f76746c1;
  box-shadow: 0 0 10px #a3783c;
}

.info-item strong {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

.info-item p {
  margin: 0;
}

.info-item a {
  color: #0066cc;
  text-decoration: none;
}

.info-item a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .contact-info {
    flex-direction: row;
    justify-content: center;
  }

  .image-section,
  .info-section {
    flex: 1;
    margin: 20px;
  }

  .image-section {
    margin-right: 20px;
  }

  .info-section h2 {
    text-align: left;
  }
}

/* Further adjustments for even smaller screens */
@media screen and (max-width: 480px) {
  .info-section {
    padding: 15px;
    margin-right: 0;
  }

  .info-item {
    margin-bottom: 10px;
  }
}
@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}