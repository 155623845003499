/* welcome */
.section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f8f9fa;
}

.section-image {
  flex: 1 1 40%;
  max-width: 30%;
  padding: 10px;
}

.section-image img {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.section-content {
  flex: 1 1 60%;
  max-width: 60%;
  padding: 20px;
  text-align: left;
}

.section-content h1,
.section-content h2,
.section-content p {
  margin: 10px 0;
}
.section-content p {
  margin: 10px 0;
  text-align: justify;
}

.section-content h2 span {
  font-size: 1.5rem;
}

@media (max-width: 992px) {
  .section {
    flex-direction: column;
  }

  .section-image,
  .section-content {
    flex: 1 1 100%;
    max-width: 100%;
    text-align: center;
  }
}


h1 {
  /* text-align: center; */
  margin: 20px;
  color: #ff5733;
}


/*service */
.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
}

.feature-item {
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1.5rem;
  flex: 1 1 calc(25% - 40px);
  margin: 20px;
  transition: transform 0.3s ease-in-out;
  text-align: center;
}

.feature-item:hover {
  transform: translateY(-10px);
}

.icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 1rem;
}

/* Responsive styling */
@media (max-width: 992px) {
  .feature-item {
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 576px) {
  .feature-item {
    flex: 1 1 100%;
  }
}
/* About */
.about-us {
  text-align: center;
  margin-bottom: 20px;
}

.about-us h2 {
  font-size: 2em;
  color: #ff8c00;
}

.about-us p {
  color: #555;
  margin: 10px 0;
}

.experience {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.experience div {
  text-align: center;
}

.experience h3 {
  font-size: 2em;
  color: #ff8c00;
}

.gallery {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.gallery img {
  width: 22%;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
