/* .active{
    color: #000000;
} */

/* nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ff5733;
    color: white;
    position: sticky;
    top: 0;
    left: 0;

}
.logo{
    width: 100px;
    margin-left: 60%;
}

nav .title {
    font-size: 1.7rem;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
}

nav ul {
    display: flex;
    padding-right: 3%;
}

nav ul li {
    list-style: none; 
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
} */

/* nav ul li a:not(.active):hover {
    background-color:#ff5733 ;
} */

/* Add this rule for the active menu item */
/* ul li a.active {
    color: #ff5733;
    background-color: #ffffff;
  }

nav .menu {
    display: none;
    position: absolute;
    top: 1.6rem;
    right: 1.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: #fff;
    border-radius: 0.2rem;
}


@media (max-width: 480px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
} */









  
  header {
    background-color: #ff5733;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .nav.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    flex-direction: row;
  }
  
  .nav__logo {
    /* height: 20px; Adjust the size as needed */
    width: 90px;
    margin-left: 10px;
  }
  
  .nav__toggle {
    display: none;
    font-size: 3rem;
    cursor: pointer;
    color: #fff;
  }
  
  .nav__menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background: #ff5733;
    transition: 0.3s;
    z-index: 1000;
  }
  
  .nav__menu.show-menu {
    right: 0;
  }
  
  .nav__close {
    font-size: 2.5rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #fff;
  }
  
  .nav__list,
  .nav__list__web {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.4rem;
    margin-right: 20px;
  }
  
  .nav__list__web {
    flex-direction: row;
  }
  
  .nav__link {
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
  }
  
  .nav__cta {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 4px;
  }
  
  .nav__link.active {
    color: #ff5733;
    background-color: #ffffff;
    padding: 4px;
    border-radius: 10px;
    }
  
  .nav__cta:hover {
    background-color: #0056b3;
  }
  
  .menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  .menu span {
    height: 2px;
    background-color: #fff;
    margin: 4px 0;
    width: 25px;
  }
  
  /* Responsive Styles */
  @media (max-width: 1150px) {
    .nav__toggle {
      display: block;
    }
  
    .nav__list,
    .nav__list__web {
      flex-direction: column;
      gap: 1rem;
    }
  
    .nav__menu ul.open {
      display: flex;
      margin-top: 50px;
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media (min-width: 1151px) {
    .nav__menu {
      position: static;
      height: auto;
      width: auto;
      background: none;
      flex-direction: row;
    }
  
    .nav__list__web {
      flex-direction: row;
    }
  }
  @media (max-width: 480px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}